<template>
	<div class="userinfo-container">
		<div class="userinfo-box">
			<div class="show-container">
				<el-col :span="12">
					<div class="info-container">
						<p style="padding: 30px;font-size: 30px;">修改个人信息</p>
						<el-form label-width="80px" label-position="left" :model="userInfo" :rules="userInfoRules" ref="userInfoForm">
							<el-form-item label="用户名">
								<el-input v-model="userInfo.userName" placeholder="用户名" disabled />
							</el-form-item>
							<el-form-item label="用户昵称" prop="nickName">
								<el-input v-model="userInfo.nickName" placeholder="用户昵称" />
							</el-form-item>
							<el-form-item label="手机号" prop="phoneNumber">
								<el-input v-model="userInfo.phoneNumber" placeholder="手机号" />
							</el-form-item>
						</el-form>
						<div style="text-align:right;">
							<el-button type="primary" @click="submitUserInfo">保存</el-button>
						</div>
						<p style="padding: 30px;font-size: 30px;">修改密码</p>
						<el-form label-width="80px" label-position="left" :model="passwordInfo" :rules="passWordRules" ref="passwordForm">
							<el-form-item label="当前密码" prop="oldPassword">
								<el-input v-model="passwordInfo.oldPassword" type='password' placeholder="当前密码" />
							</el-form-item>
							<el-form-item label="新密码" prop="password">
								<el-input v-model="passwordInfo.password" placeholder="新密码" type='password' />
							</el-form-item>
							<el-form-item label="确认密码" prop="surePassword">
								<el-input v-model="passwordInfo.surePassword" placeholder="确认密码" type='password' />
							</el-form-item>
						</el-form>
						<div style="text-align:right;">
							<el-button type="primary" @click="submitPassword">保存</el-button>
						</div>
					</div>
				</el-col>
			</div>
		</div>
	</div>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
  data () {
    var validatePass2 = (rule, value, callback) => {
      if (value === '' && this.passwordInfo.password != '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.passwordInfo.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userInfo: {
        id: 0,
        userName: '',
        nickName: '',
        phoneNumber: ''
      },
      passwordInfo: {
        id: 0,
        oldPassword: '',
        password: '',
        surePassword: ''
      },
      userInfoRules: {
        nickName: [{
          required: true,
          message: '请输入用户昵称',
          trigger: 'blur'
        }],
        phoneNumber: [{
          required: true,
          message: '手机号不得为空',
          trigger: 'blur'
        }]
      },
      passWordRules: {
        oldPassword: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        }],
        surePassword: [{
          required: true,
          message: '请再次输入新密码',
          trigger: 'blur'
        }, {
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapMutations('user', {
      initUserInfo: 'initUserInfo'
    }),
    init () {
      // this.userInfo = JSON.parse(JSON.stringify(JSON.parse(localStorage['USER_INFO'])))
      let nowUserInfo = JSON.parse(JSON.stringify(JSON.parse(localStorage['USER_INFO'])))
      const {
        userId,
        userName,
        nickName,
        phoneNumber
      } = nowUserInfo
      this.passwordInfo.id = userId
      this.userInfo.id = userId
      this.userInfo.userName = userName
      this.userInfo.nickName = nickName
      this.userInfo.phoneNumber = phoneNumber
    },
    submitUserInfo () {
      this.$refs.userInfoForm.validate((valid) => {
        if (valid) {
          this.$axios.post('/api/user/updateuserinfo', this.userInfo).then(res => {
            var nowUserInfo = JSON.parse(JSON.stringify(JSON.parse(localStorage['USER_INFO'])))
            nowUserInfo.phoneNumber = this.userInfo.phoneNumber
            nowUserInfo.nickName = this.userInfo.nickName
            localStorage.setItem('USER_INFO', JSON.stringify(nowUserInfo))
            localStorage.setItem('nickName', this.userInfo.nickName)
            let userInfoV = this.$store.state.user.userInfo
            userInfoV.phoneNumber = this.userInfo.phoneNumber
            userInfoV.nickName = this.userInfo.nickName
            this.initUserInfo(userInfoV)
            this.$message({
              type: 'success',
              message: '修改成功'
            })
          })
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    submitPassword () {
      // passwordInfo: {
      // 	id:0,
      // 	oldPassword: '',
      // 	password: '',
      // 	surePassword: ''
      // },
      this.$refs.passwordForm.validate((valid) => {
        if (valid) {
          this.$axios.get(`/api/user/updateuserpassword?userId=${this.passwordInfo.id}&password=${this.passwordInfo.surePassword}&oldPassword=${this.passwordInfo.oldPassword}`).then(res => {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
            if (this.userInfo.password != '') {
              this.$message({
                type: 'error',
                message: '该账号密码已更改，请重新登录。'
              })
              this.$router.push('/login')
            }
          })
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    },
    submitEditUser () {
      this.$refs.uploadUser.validate((valid) => {
        if (valid) {
          this.$axios.post('/api/user/updateuserinfo', this.userInfo).then(res => {
            localStorage.setItem('nickName', this.userInfo.nickName)
            let userInfoV = this.$store.state.user.userInfo
            userInfoV.phoneNumber = this.userInfo.phoneNumber
            userInfoV.nickName = this.userInfo.nickName
            this.initUserInfo(userInfoV)
            this.$message({
              type: 'success',
              message: '修改成功'
            })
            if (this.userInfo.password != '') {
              this.$message({
                type: 'error',
                message: '该账号密码已更改，请重新登录。'
              })
              this.$router.push('/login')
            }
          })
        } else {
          // console.log('error submit!!');
          return false
        }
      })
    }

  }
}
</script>

<style scoped lang="less">
	.userinfo-container {
		width: 100%;
		// height: calc(100vh - 60px);
		height: 877px;

		.el-button--primary {
			background-color: #475065;
			border: none;
		}

		.userinfo-box {
			width: 100%;
			height: 100%;
			padding: 20px 150px;
			box-sizing: border-box;

			// background-color: #000000;
			.btn-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 20px 0 0;
				font-size: 18px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #343434;
				margin-bottom: 10px;

				// background-color: #000000;
			}

			.show-container {
				width: 100%;
				height: 100%;
				// height: calc(100% - 20px);
				background-color: #fff;
				// background-color: #000066;
				display: flex;
				justify-content: center;

				.info-container {
					height: 100%;
					box-sizing: border-box;
					overflow-y: auto;
				}

				.password-container {
					width: 35%;
					height: 100%;
					background-color: #fff;
					box-sizing: border-box;
					padding: 100px 90px;
					overflow-y: auto;
				}
			}
		}
	}
</style>
